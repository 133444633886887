<template>
    <v-app>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title pt-3 px-3 d-flex justify-content-between">
                <div class="breadcrumb-left">
                  <h4>Academic year (Batch)</h4>
                  <div class="breadcrumb-sub-header">
                    <router-link to="/dashboard">Dashboard</router-link>
                    \ Academic year
                  </div>
                </div>
                <div class="breadcrumb-right" v-if="checkIsAccessible('academic-year', 'create')">
                  <div class="card-toolbar">
                    <v-btn @click="openDialog()" class="mt-4 btn btn-primary" style="color: #fff">
                      <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                      Add new
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="">
                <v-skeleton-loader
                    type="table-thead"
                    v-if="loading"
                >
                </v-skeleton-loader>

                <v-skeleton-loader
                    v-if="loading"
                    type="table-row-divider@25"
                >
                </v-skeleton-loader>
                <table class="table" v-if="!loading">
                  <thead>
                  <tr class="px-3">
                    <th>Title</th>
                    <th>Year</th>
                    <th>Is current</th>
                    <th>Is complete</th>
                    <th>Is Auto increment fee</th>
                    <th class="pr-3 text-center">Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(academicYear, index) of academicYears" :key="index">
                    <td>
                      <a href="#" @click="openDialog(academicYear.id)"> {{academicYear.title ? academicYear.title : 'N/A'}}</a>
                      <i class="fas fa-circle ml-2"
                         :class="academicYear.is_active?'dot-active':'dot-inactive'"></i>
                    </td>
                    <td><a href="#" @click="openDialog(academicYear.id)"> {{academicYear.year}}</a></td>

                    <td class="pt-3">
                      <span class="badge" :class="academicYear.is_current_year ? 'badge-success' : 'badge-danger' ">{{ academicYear.is_current_year ? 'Yes' : 'No' }}</span>
                    </td>

                    <td class="pt-3">
                      <span class="badge" :class="academicYear.is_complete ? 'badge-success' : 'badge-danger' ">{{ academicYear.is_complete ? 'Yes' : 'No' }}</span>
                    </td>

                    <td class="pt-3">
                      <span class="badge" :class="academicYear.is_auto_increment_fee ? 'badge-success' : 'badge-danger' ">{{ academicYear.is_auto_increment_fee ? 'Yes' : 'No' }}</span>
                    </td>

                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip>
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item"  v-if="checkIsAccessible('academic-year', 'edit')">
                              <a class="navi-link" @click="openDialog(academicYear.id)">
                                  <span class="navi-icon">
                                      <i class="fas fa-edit"></i>
                                  </span>
                                <span class="navi-text">Edit</span>
                              </a>
                            </b-dropdown-text>

                            <b-dropdown-text tag="div" class="navi-item"  v-if="checkIsAccessible('academic-year', 'edit') && academicYear.is_current_year && !academicYear.is_auto_increment_fee && !academicYear.is_complete">
                              <a class="navi-link" @click="enableAutoIncrementFee(academicYear.id)">
                                  <span class="navi-icon">
                                      <i class="fas fa-money-bill"></i>
                                  </span>
                                <span class="navi-text">Enable auto increment fee</span>
                              </a>
                            </b-dropdown-text>

                            <b-dropdown-text tag="div" class="navi-item"  v-if="checkIsAccessible('academic-year', 'edit') && academicYear.is_current_year && academicYear.is_auto_increment_fee && !academicYear.is_complete">
                              <a class="navi-link" @click="disableAutoIncrementFee(academicYear.id)">
                                  <span class="navi-icon">
                                      <i class="fas fa-money-bill-alt"></i>
                                  </span>
                                <span class="navi-text">Disable auto increment fee</span>
                              </a>
                            </b-dropdown-text>

                            <b-dropdown-text tag="div" class="navi-item"  v-if="checkIsAccessible('academic-year', 'edit') && !academicYear.is_complete">
                              <a class="navi-link" @click="markAsComplete(academicYear,1)">
                                  <span class="navi-icon">
                                      <i class="fas fa-check"></i>
                                  </span>
                                <span class="navi-text">Mark as complete</span>
                              </a>
                            </b-dropdown-text>

                            <b-dropdown-text tag="div" class="navi-item"  v-if="checkIsAccessible('academic-year', 'edit') && !academicYear.is_current_year && academicYear.is_active">
                              <a class="navi-link" @click="setAsCurrentYear(academicYear.id)">
                                  <span class="navi-icon">
                                      <i class="fas fa-check"></i>
                                  </span>
                                <span class="navi-text">Set as current year</span>
                              </a>
                            </b-dropdown-text>

                            <b-dropdown-text tag="div" class="navi-item"  v-if="checkIsAccessible('academic-year', 'edit') && academicYear.is_complete">
                              <a class="navi-link" @click="markAsComplete(academicYear,0)">
                                  <span class="navi-icon">
                                      <i class="fas fa-check" style="color: blue"></i>
                                  </span>
                                <span class="navi-text">Mark as incomplete</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('academic-year' ,'delete')" >
                              <a class="navi-link" @click.prevent="deleteAcademicYear(academicYear.id)">
                                  <span class="navi-icon">
                                     <i class="fas fa-trash"></i>
                                  </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="academicYears.length == 0">
                    <td  class="px-3 text-center" colspan="6"><strong>No data available to display.</strong></td>
                  </tr>
                  </tbody>
                </table>
                <b-pagination   v-if="academicYears.length > 0"
                                class="pull-right mt-7"
                    @input="getAcademicYears"
                    v-model="page"
                    :total-rows="total"
                    :per-page="perPage"
                    first-number
                    last-number
                ></b-pagination>
              </div>

            </div>
            <create-and-update ref="department" :academic_id="academic_id" @close_dialog="closeDialog"
                               @refresh="getAcademicYears"></create-and-update>
        </div>
        </div>
      </div>
    </v-app>
</template>
<script>
import CreateAndUpdate from "./CreateAndUpdate";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";

const academicYearService = new AcademicYearService();

export default {
  name: "Department",
  components: {
      CreateAndUpdate
  },
  data() {
      return {
          academicYears: [],
          academic_id: null,
          page: null,
          total: null,
          perPage: null,
          loading:false,
      };
  },
  mounted() {
      this.getAcademicYears();
  },
  methods: {
      getAcademicYears() {
        this.loading=true;
          academicYearService.paginate().then(response => {
              this.academicYears = response.data.data;
              this.page = response.data.meta.current_page;
              this.total = response.data.meta.total;
              this.perPage = response.data.meta.per_page;
              this.loading=false;
          });
      },

      markAsComplete(academicYear,completeStatus){
        if(completeStatus==1){
          academicYear.is_complete=completeStatus;
        }
        if(completeStatus==0){
          academicYear.is_complete=completeStatus;
        }
        academicYearService
            .markAsComplete(academicYear.id,academicYear)
            .then(response => {
              if(completeStatus){
                this.$snotify.success("Mark as complete");
              }
              if(!completeStatus) {
                this.$snotify.success("Mark as incomplete");
              }
            })
            .catch((err) => {

            })
            .finally(() => {
              this.getAcademicYears();
            });
      },

      openDialog(id) {
          this.academic_id = id;
          this.$refs["department"].showModal(id);
      },

      closeDialog(id) {
          this.academic_id = id;
          this.$refs["department"].hideModal();
          this.getAcademicYears();
          this.academic_id = null;
      },

      enableAutoIncrementFee(id) {
          this.$confirm({
              message: `Are you sure you want to enable auto increment fee this item?`,
              button: {
                  no: "No",
                  yes: "Yes"
              },
              callback: confirm => {
                  if (confirm) {
                      academicYearService.enableAutoIncrementFee(id).then(response => {
                          this.$snotify.success("Information updated");
                          this.getAcademicYears();
                      });
                  }
              }
          });
      },

      disableAutoIncrementFee(id) {
          this.$confirm({
              message: `Are you sure you want to disable auto increment fee this item?`,
              button: {
                  no: "No",
                  yes: "Yes"
              },
              callback: confirm => {
                  if (confirm) {
                      academicYearService.disableAutoIncrementFee(id).then(response => {
                          this.$snotify.success("Information updated");
                          this.getAcademicYears();
                      });
                  }
              }
          });
      },

      deleteAcademicYear(id) {
        this.$confirm({
            message: `Are you sure you want to delete this item?`,
            button: {
                no: "No",
                yes: "Yes"
            },
            callback: confirm => {
                if (confirm) {
                    academicYearService.delete(id).then(response => {
                        this.$snotify.success("Information deleted");
                        this.getAcademicYears();
                    });
                }
            }
        });
      },

      setAsCurrentYear(academicYearId){
        this.$confirm({
          message: `Are you sure you want to set as current year?`,
          button: {
              no: "No",
              yes: "Yes"
          },
          callback: confirm => {
              if (confirm) {
                  academicYearService
                    .setAsCurrentYear(academicYearId)
                    .then(response => {
                      this.$snotify.success("Information updated");
                      this.getAcademicYears();
                  });
              }
          }
        });
      }
  }
};
</script>
