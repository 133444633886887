<template>
  <v-app>
    <v-dialog v-model="dialog" max-width="600">
      <v-card>
        <v-toolbar dark>
          <v-card-title class="headline">
            {{`${edit?'Update':"Add"} academic year`}}
            <hr>
          </v-card-title>

          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon  @click="hideModal">
              <i class="fas fa-times"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <div class="row">
            <div class="col-12">
              <v-text-field v-model="academicYear.title" outlined dense label="Title( E.g. 2073/74 or 2021)">
              </v-text-field>
              <span class="text-danger" v-if="$v.academicYear.title.$error"
                >Title is required</span
              >
            </div>
            <div class="col-12">
              <v-select
                :items="years"
                v-model="academicYear.year"
                outlined
                dense
                label="Select year"
              >
              </v-select>
              <span class="text-danger" v-if="$v.academicYear.year.$error"
                >This information is required</span
              >
            </div>

            <div class="col-3">
              <v-switch
                v-model="academicYear.is_active"
                name="check-button"
                class
                switch
                label="Status"
                >
              </v-switch>
            </div>

            <div class="col-12">
              <v-switch
                v-model="academicYear.is_current_year"
                name="check-button"
                class
                switch
                label="Current year"
                >
              </v-switch>
            </div>

            <div class="col-12 text-right mt-4">
              <v-btn class="btn btn-standard cancel-btn" depressed @click="hideModal">Cancel </v-btn>
              <v-btn v-if="checkIsAccessible('academic-year', 'create')"
                class="text-white ml-2 btn btn-primary"
                depressed
                @click="createAndUpdate"
                :loading="isBusy"
                >Save
              </v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";

const academicYearService = new AcademicYearService();

export default {
  props: ["message_id"],
  name: "academicYear-create-and-update",
  validations: {
    academicYear: {
      title: { required },
      year: { required },
    },
  },
  data() {
    return {
      edit: false,
      isBusy: false,
      dialog: false,
      academicYear: {
        year: null,
        title: null,
        is_active: true,
        is_current_year: false
      },
    };
  },
  methods: {
    getAcademicYear(id) {
      academicYearService.show(id).then((response) => {
        this.academicYear = response.data.academicYear;
        this.academicYear.year = parseInt(this.academicYear.year);
        this.academicYear.is_active = this.academicYear.is_active ? true : false;
      });
    },
    resetData() {
      this.academicYear = {
          year: null,
          title: null,
          is_active: true,
          is_current_year: false,
      };
      this.edit = false;
    },
    showModal(id = null) {
      if (id) {
        this.getAcademicYear(id);
        this.edit = true;
      }
      if(id==null){
        this.resetData();
      }
      this.dialog = true;
    },
    hideModal() {
      this.isBusy = false;
      this.dialog = false;
      this.resetData();
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["my-modal"].toggle("#toggle-btn");
    },
    createAndUpdate() {
      this.$v.$touch();
      if (this.$v.academicYear.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
        if (this.edit) {
          this.updateAcademicYear();
        } else {
          this.createAcademicYear();
        }
      }
    },
    createAcademicYear() {
      this.isBusy = true;
      academicYearService
        .store(this.academicYear)
        .then((response) => {
          this.isBusy = false;
          if (!response.data.status) {
            this.$snotify.error(response.data.msg);
          } else {
            this.resetFaculty();
            this.$snotify.success("Information added");
            this.dialog = false;
            this.$emit("refresh");
          }
        })
        .catch((error) => {
          this.$snotify.error("Something went wrong");
        });
    },
    updateAcademicYear() {
      this.isBusy = true;
      academicYearService
        .update(this.academicYear.id, this.academicYear)
        .then((response) => {
          this.isBusy = false;
          this.resetFaculty();
          this.$snotify.success("Information updated");
          this.dialog = false;
          this.$emit("refresh");
        })
        .catch((error) => {
          this.$snotify.error("Something went wrong");
        });
    },
    resetFaculty() {
      this.academicYear = {
        year: null,
        is_active: true,
      };
      this.edit = false;
      this.$v.$reset();
    },
  },
  computed: {
    years() {
      let currentYear = new Date().getFullYear();
      let array = [];
      for (let i = currentYear + 6; i >= currentYear - 30; i--) {
        array.push(i);
      }
      return array;
    },
  },
};
</script>
